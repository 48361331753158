    /* Main container for the layout */
    .container {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
    }

    /* Left column: Photo gallery */
    .left-column {
        flex: 2;
        background-color: #f0f0f0;
        overflow-x: auto;
        white-space: nowrap;
        display: flex;
        align-items: center;
    }

    .gallery {
        display: flex;
        gap: 10px;
        padding: 10px;
    }

    .gallery img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 2px solid #ccc;
        border-radius: 5px;
    }

    /* Right column: Descriptions */
    .right-column {
        flex: 1;
        background-color: #fff;
        overflow-y: auto;
        padding: 20px;
        border-left: 2px solid #ddd;
    }

    .OrderTitle{
        margin-bottom: 20px;
    }

 .OrderButton{
    margin: 20px 20px;
 }

    /* Responsive Design */
    @media (max-width: 768px) {
        .container {
            flex-direction: column;
        }

        .left-column,
        .right-column {
            flex: 1;
            height: 50%;
        }

        .gallery {
            flex-wrap: nowrap;
        }
    }

    @media (max-width: 480px) {

        .left-column,
        .right-column {
            height: auto;
        }

        .gallery img {
            width: 150px;
            height: 100px;
        }
    }