@import './reset.css';
@import './variables.css';
@import './App.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


/* 
===================
Body Global Style
=================== 
*/

body {
  background-color: var(--bg-clr);
  color: var(--text-clr);
  font-family: var(--ff-body);
  font-size: var(--body-text);
  font-weight: 400;
  letter-spacing: var(--tracking);
  line-height: 1.4;
  min-height: 100vh;
}


/* Headings Global Styles */

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}

h1 {
  /* font-size: var(--h1); */
}

h2 {
  /* font-size: var(--h2); */
}

h3 {
  /* font-size: var(--h3); */
}

h1,
h2,
h3,
h4 {
  letter-spacing: var(--tracking);
}


/* Base interactive elements */

a {
  color: inherit;
}

a:hover {
  text-decoration: none;
  font-weight: 700;
}

:focus {
  outline: 2px solid;
  outline-offset: 0.3ch;
}

:target {
  scroll-margin-top: 2ex;
}

main:focus {
  outline: none;
}

/* Base selection styles that invert whatever colours are rendered */
::selection {
  background: var(--text-clr);
  color: var(--bg-clr);
}


/* 
-------------------------------------
           Page Not Found
-------------------------------------
*/

.container_notFoundPage {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  height: 100vh;
  padding: 30px;
}

.content_notFoundPage,
.image_notFoundPage {
  flex: 1;
}

.content_notFoundPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.image_notFoundPage img {
  width: 100%;
  height: auto;
}

.buttons_notFoundPage {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
}

.buttons_notFoundPage a {
  color: var(--text-light);
  background-color: var(--text-clr);
  border-radius: .4375rem;
  padding: 10px 35px;
  text-decoration: none;
}

.buttons_notFoundPage a:hover {
  border: 2px solid var(--clr-primary-text);
  background-color: transparent;
  color: var(--clr-primary-text);
}

/* .buttons_notFoundPage a:nth-child(1) {
  background-color: var(--clr-accent);
} */

@media screen and (min-width:340px) and (max-width:768px) {
  .container_notFoundPage {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: .3125rem;
    padding: 30px;
    height: 100%;
  }

  .content_notFoundPage {
    margin-top: 5px;
  }

  .text_notFoundPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    line-height: 1.5;
  }

  .text_notFoundPage h1 {
    font-size: 36px;
    line-height: 1.2;
  }

  .text_notFoundPage h3 {
    font-size: 18px;
    line-height: 1.2;
  }

  .buttons_notFoundPage {
    flex-direction: column;
  }

  .buttons_notFoundPage a {
    width: 100%;
    text-align: center;
  }
}

@media screen and (min-width:541px) and (max-width:1200px) {
  .buttons_notFoundPage {
    display: flex;
    flex-direction: column;
  }

  .buttons_notFoundPage a {
    width: 100%;
    text-align: center;
  }

}