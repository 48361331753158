nav {
    height: 4.375rem;
    background-color: var(--bg-clr);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* position: fixed;
    top: 0; */
    width: 100%;
    z-index: 999;
    box-shadow: var(--text-clr) 2px 2px 3px;
}

.links-container {
    width: 80%;
    margin: auto;
    display: flex;
    gap: 30px;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.logo {
    flex: 1;
    display: flex;
    justify-content: start;
    align-items: center;
}

.logo img {
    width: 120px;
    height: auto;
}

.menu-items {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--space-l);
}

.social-media-icons {
    flex: 1;
    display: flex;
    justify-content: end;
    gap: var(--space-m);
}

.social-media-icons img {
    width: 30px;
    height: 30px;
}

nav a {
    text-decoration: none;
    color: inherit;
}

nav a:hover {
    color: var(--text-clr);
    font-weight: 700;
}

nav .home-link {
    margin-right: auto;
}

nav svg {
    fill: var(--color-primary-text);
}

i{
    font-size: 30px;
}

#sidebar-active {
    display: none;
}

.open-sidebar-button,
.close-sidebar-button {
    display: none;
}


@media only screen and (max-width: 1024px) {
    .links-container {
        flex-direction: column;
        align-items: flex-start;

        position: fixed;
        top: 0;
        right: -100%;
        z-index: 10;
        width: 300px;

        background-color: var(--color-primary-light);
        box-shadow: -5px 0 5px rgba(0, 0, 0, 0.25);
        transition: 0.75s ease-out;
    }

    .menu-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }

    .menu-items a:hover {
        color: var(--color-primary-dark);
    }

    .social-media-icons {
        display: none;
    }

    .social-media-icons img {
        width: 20px;
        height: 20px;
    }

    nav a {
        box-sizing: border-box;
        height: auto;
        width: 100%;
        padding: 20px 30px;
        justify-content: flex-start;
    }

    .open-sidebar-button,
    .close-sidebar-button {
        padding: 20px;
        display: block;
    }

    #sidebar-active:checked~.links-container {
        right: 0;
    }

    #sidebar-active:checked~#overlay {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
    }
}


@media only screen and (max-width: 540px) {
    .links-container {
        flex-direction: column;
        align-items: flex-start;

        position: fixed;
        top: 0;
        right: -100%;
        z-index: 10;
        width: 300px;

        background-color: var(--color-primary-light);
        box-shadow: -5px 0 5px rgba(0, 0, 0, 0.25);
        transition: 0.75s ease-out;
    }

    .menu-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }

    .menu-items a:hover {
        color: var(--color-primary-dark);
    }

    .social-media-icons {
        display: none;
    }

    .social-media-icons img {
        width: 20px;
        height: 20px;
    }

    nav a {
        box-sizing: border-box;
        height: auto;
        width: 100%;
        padding: 20px 30px;
        justify-content: flex-start;
    }

    .open-sidebar-button,
    .close-sidebar-button {
        padding: 20px;
        display: block;
    }

    #sidebar-active:checked~.links-container {
        right: 0;
    }

    #sidebar-active:checked~#overlay {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
    }
}


@media (max-width: 450px) {
    .links-container {
        flex-direction: column;
        align-items: flex-start;

        position: fixed;
        top: 0;
        right: -100%;
        z-index: 10;
        width: 300px;

        background-color: var(--color-primary-light);
        box-shadow: -5px 0 5px rgba(0, 0, 0, 0.25);
        transition: 0.75s ease-out;
    }

    .menu-items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2px;
    }

    .menu-items a:hover {
        color: var(--color-primary-dark);
    }

    .social-media-icons {
        display: none;
    }

    .social-media-icons img {
        width: 20px;
        height: 20px;
    }

    nav a {
        box-sizing: border-box;
        height: auto;
        width: 100%;
        padding: 20px 30px;
        justify-content: flex-start;
    }

    .open-sidebar-button,
    .close-sidebar-button {
        padding: 20px;
        display: block;
    }

    #sidebar-active:checked~.links-container {
        right: 0;
    }

    #sidebar-active:checked~#overlay {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
    }
}