:root {

    /*Site Colors*/
    --bg-clr: #bbdefb;
    --dark: #1976d2;
    --accent: #2196f3;
    --text-clr: #052945;
    --text-light: #ffffff;

    /* Fonts */
    --ff-body: 'Inter', sans-serif;
    --ff-heading: 'Inter', sans-serif;
    --ff-ui: 'Inter', sans-serif;
    /* fonts ends */

    /* Font Sizes */

    /* Step -2: 12.5px → 12.8px */
    --small-text: clamp(0.7813rem, 0.775rem + 0.0313vi, 0.8rem);
    /* Step -1: 15px → 16px */
    --ui: clamp(0.9375rem, 0.9167rem + 0.1042vi, 1rem);
    /* Step 0: 18px → 20px */
    --body-text: clamp(1.125rem, 1.0833rem + 0.2083vi, 1.25rem);
    /* Step 1: 21.6px → 25px */
    --h6: clamp(1.35rem, 1.2792rem + 0.3542vi, 1.5625rem);
    /* Step 2: 25.92px → 31.25px */
    --h5: clamp(1.62rem, 1.509rem + 0.5552vi, 1.9531rem);
    /* Step 3: 31.104px → 39.0625px */
    --h4: clamp(1.944rem, 1.7782rem + 0.829vi, 2.4414rem);
    /* Step 4: 37.3248px → 48.8281px */
    --h3: clamp(2.3328rem, 2.0931rem + 1.1983vi, 3.0518rem);
    /* Step 5: 44.7898px → 61.0352px */
    --h2: clamp(2.7994rem, 2.4609rem + 1.6922vi, 3.8147rem);
    /* Step 6: 53.7477px → 76.2939px */
    --h1: clamp(3.3592rem, 2.8895rem + 2.3486vi, 4.7684rem);
    /* Step 7: 64.4973px → 95.3674px */
    --title4: clamp(4.0311rem, 3.3879rem + 3.2156vi, 5.9605rem);
    /* Step 8: 77.3967px → 119.2093px */
    --title3: clamp(4.8373rem, 3.9662rem + 4.3555vi, 7.4506rem);
    /* Step 9: 92.876px → 149.0116px */
    --title2: clamp(5.8048rem, 4.6353rem + 5.8475vi, 9.3132rem);
    /* Step 10: 111.4513px → 186.2645px */
    --title1: clamp(6.9657rem, 5.4071rem + 7.793vi, 11.6415rem);

    /* Spaces */
    /* Space 3xs: 5px → 5px */
    --space-3xs: clamp(0.3125rem, 0.3125rem + 0vi, 0.3125rem);
    /* Space 2xs: 9px → 10px */
    --space-2xs: clamp(0.5625rem, 0.5417rem + 0.1042vi, 0.625rem);
    /* Space xs: 14px → 15px */
    --space-xs: clamp(0.875rem, 0.8542rem + 0.1042vi, 0.9375rem);
    /* Space s: 18px → 20px */
    --space-s: clamp(1.125rem, 1.0833rem + 0.2083vi, 1.25rem);
    /* Space m: 27px → 30px */
    --space-m: clamp(1.6875rem, 1.625rem + 0.3125vi, 1.875rem);
    /* Space l: 36px → 40px */
    --space-l: clamp(2.25rem, 2.1667rem + 0.4167vi, 2.5rem);
    /* Space xl: 54px → 60px */
    --space-xl: clamp(3.375rem, 3.25rem + 0.625vi, 3.75rem);
    /* Space 2xl: 72px → 80px */
    --space-2xl: clamp(4.5rem, 4.3333rem + 0.8333vi, 5rem);
    /* Space 3xl: 108px → 120px */
    --space-3xl: clamp(6.75rem, 6.5rem + 1.25vi, 7.5rem);

    /* One-up pairs */

    /* Space 3xs-2xs: 5px → 10px */
    --space-3xs-2xs: clamp(0.3125rem, 0.2083rem + 0.5208vi, 0.625rem);
    /* Space 2xs-xs: 9px → 15px */
    --space-2xs-xs: clamp(0.5625rem, 0.4375rem + 0.625vi, 0.9375rem);
    /* Space xs-s: 14px → 20px */
    --space-xs-s: clamp(0.875rem, 0.75rem + 0.625vi, 1.25rem);
    /* Space s-m: 18px → 30px */
    --space-s-m: clamp(1.125rem, 0.875rem + 1.25vi, 1.875rem);
    /* Space m-l: 27px → 40px */
    --space-m-l: clamp(1.6875rem, 1.4167rem + 1.3542vi, 2.5rem);
    /* Space l-xl: 36px → 60px */
    --space-l-xl: clamp(2.25rem, 1.75rem + 2.5vi, 3.75rem);
    /* Space xl-2xl: 54px → 80px */
    --space-xl-2xl: clamp(3.375rem, 2.8333rem + 2.7083vi, 5rem);
    /* Space 2xl-3xl: 72px → 120px */
    --space-2xl-3xl: clamp(4.5rem, 3.5rem + 5vi, 7.5rem);

    /* Custom pairs */

    /* Space s-l: 18px → 40px */
    --space-s-l: clamp(1.125rem, 0.6667rem + 2.2917vi, 2.5rem);
    /* Space m-xl: 27px → 60px */
    --space-m-xl: clamp(1.6875rem, 1rem + 3.4375vi, 3.75rem);
    /* Space m-2xl: 27px → 80px */
    --space-m-2xl: clamp(1.6875rem, 0.5833rem + 5.5208vi, 5rem);
    /* Space l-3xl: 36px → 120px */
    --space-l-3xl: clamp(2.25rem, 0.5rem + 8.75vi, 7.5rem);

    /* Grid Gutters Sizes */

    --grid-gutter: var(--space-s-l, clamp(12px, 2.4768px + 2.9762vw, 32px));

    /* gutter sizes */

    --gutter: var(--space-s-m);

    /* border size */

    --border-radius: var(--size-step-1);
    --border-thick: var(--size-step-2);
    --border-thin: var(--space-3xs);

    /* letter spacing */

    --tracking: -0.05ch;
    --tracking-s: -0.075ch;
    --tracking-m: -0.1ch;
    --tracking-l: -0.125ch;
    --tracking-xl: -0.15ch;
    --tracking-2xl: -0.175ch;
    --tracking-3xl: -0.2ch;

    /* transition sizes */

    --transition-base: 250ms ease;
    --transition-movement: 200ms linear;
    --transition-fade: 200ms ease;
    --transition-bounce: 500ms cubic-bezier(0.5, 0.05, 0.2, 1.5);

    /* Image Size */

    --img-size-16-64: clamp(16px, -0.696px + 5.2174vi, 64px);
    --img-size-64-100: clamp(64px, 51.4784px + 3.913vi, 100px);
    --img-size-100-300: clamp(100px, 30.4352px + 21.7391vi, 300px);
    --img-size-300-500: clamp(300px, 230.4352px + 21.7391vi, 500px);
    --img-size-500-700: clamp(500px, 430.4352px + 21.7391vi, 700px);
    --img-size-700-1000: clamp(700px, 595.6528px + 32.6087vi, 1000px);
}