.row__HeroHome {
    display: relative;
    height: 100vh;
    background-image: url("../heroHome/herohome.jpg");
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.bgImage{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.container__HeroHome {
    width: 80%;
    margin: auto;
}

.cta__HeroHome {
    background-color: rgba(187, 222, 251, 0.45);
    backdrop-filter: blur(5px) saturate(110%) brightness(110%);
    width: 65vh;
    height: 85vh;
    border-radius: .9375rem;
    padding: 50px 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* .cta__HeroHome h2,
h4,
p {
    text-align: center;
} */

.cta__HeroHome h2 {
    font-size: var(--h4);
    font-weight: 900;
}

.form__HeroHome {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}

.fieldLabel__Home {
    font-size: var(--ui);
    font-weight: 700;
}

.field__HeroHome {
    font-size: var(--ui);
    height: 48px;
    padding: 0 10px;
    border-radius: 5px;
    border: transparent;
}

.cta-btn__HeroHome {
    margin: 10px 0;
    background-color: var(--text-clr);
    color: var(--text-light);
}

.cta-btn__HeroHome:hover {
    background-color: var(--bg-clr);
    color: var(--text-clr);
    font-weight: 700;
}


/* 
===========================================
Media Queries
===========================================
*/

@media screen and (max-width: 1366px) {
    .cta__HeroHome h2 {
        font-size: 31px;
    }

    .cta__HeroHome h4 {
        font-size: 18px;
    }

    .subheading__HeroHome {
        font-size: 16px;
    }

    .fieldLabel__Home {
        font-size: var(--ui);
        font-weight: 600;
    }

    .field__HeroHome {
        font-size: var(--ui);
        height: 32px;
        padding: 0 10px;
        border-radius: 5px;
        border: transparent;
    }

    .cta-btn__HeroHome {
        font-size: 16px;
        margin-top: 10px;
        background-color: var(--text-clr);
        color: var(--text-light);
    }
}

@media screen and (max-width:1024px) {
    .row__HeroHome {
            height: 60vh;
            padding: 20px 0;
        }
    
        .container__HeroHome {
            width: 70%;
            height: auto;
            padding: 10px 50px;
        }
    
        .cta__HeroHome {
            width: 100%;
            height: auto;
            padding: 30px 30px;
        }
    
        .cta__HeroHome h2 {
            font-size: 31px;
        }
    
        .cta__HeroHome h4 {
            font-size: 18px;
        }
    
        .subheading__HeroHome {
            font-size: 16px;
        }
    
        .fieldLabel__Home {
            font-size: var(--ui);
            font-weight: 600;
        }
    
        .field__HeroHome {
            font-size: var(--ui);
            height: 40px;
            padding: 0 10px;
            border-radius: 5px;
            border: transparent;
        }
    
        .cta-btn__HeroHome {
            font-size: 16px;
            margin-top: 10px;
            background-color: var(--text-clr);
            color: var(--text-light);
        }
}

@media screen and (max-width:768px) {
        .row__HeroHome {
                height: 100vh;
                padding: 20px 0;
            }
        
            .container__HeroHome {
                width: 100%;
                height: auto;
                padding: 10px 50px;
            }
        
            .cta__HeroHome {
                width: 100%;
                height: auto;
                padding: 30px 30px;
            }
        
            .cta__HeroHome h2 {
                font-size: 31px;
            }
        
            .cta__HeroHome h4 {
                font-size: 18px;
            }
        
            .subheading__HeroHome {
                font-size: 16px;
            }
        
            .fieldLabel__Home {
                font-size: var(--ui);
                font-weight: 600;
            }
        
            .field__HeroHome {
                font-size: var(--ui);
                height: 40px;
                padding: 0 10px;
                border-radius: 5px;
                border: transparent;
            }
        
            .cta-btn__HeroHome {
                font-size: 16px;
                margin-top: 10px;
                background-color: var(--text-clr);
                color: var(--text-light);
            }
}

@media screen and (max-width:540px) {
        .row__HeroHome {
                height: auto;
                padding: 20px 0;
            }
        
            .container__HeroHome {
                width: 100%;
                height: auto;
                padding: 10px 10px;
            }
        
            .cta__HeroHome {
                width: 100%;
                height: auto;
                padding: 30px 30px;
            }
        
            .cta__HeroHome h2 {
                font-size: 31px;
            }
        
            .cta__HeroHome h4 {
                font-size: 18px;
            }
        
            .subheading__HeroHome {
                font-size: 16px;
            }
        
            .fieldLabel__Home {
                font-size: var(--ui);
                font-weight: 600;
            }
        
            .field__HeroHome {
                font-size: var(--ui);
                height: 40px;
                padding: 0 10px;
                border-radius: 5px;
                border: transparent;
            }
        
            .cta-btn__HeroHome {
                font-size: 16px;
                margin-top: 10px;
                background-color: var(--text-clr);
                color: var(--text-light);
            }
}

@media screen and (max-width:450px) {
        .row__HeroHome {
                height: auto;
                padding: 20px 0;
            }
        
            .container__HeroHome {
                width: 100%;
                height: auto;
                padding: 10px 10px;
            }
        
            .cta__HeroHome {
                width: 100%;
                height: auto;
                padding: 30px 30px;
            }
        
            .cta__HeroHome h2 {
                font-size: 31px;
            }
        
            .cta__HeroHome h4 {
                font-size: 18px;
            }
        
            .subheading__HeroHome {
                font-size: 16px;
            }
        
            .fieldLabel__Home {
                font-size: var(--ui);
                font-weight: 600;
            }
        
            .field__HeroHome {
                font-size: var(--ui);
                height: 40px;
                padding: 0 10px;
                border-radius: 5px;
                border: transparent;
            }
        
            .cta-btn__HeroHome {
                font-size: 16px;
                margin-top: 10px;
                background-color: var(--text-clr);
                color: var(--text-light);
            }
}

@media screen and (max-width:376px) {
    .row__HeroHome {
        height: auto;
        padding: 20px 0;
    }

    .container__HeroHome {
        width: 100%;
        height: auto;
        padding: 10px 10px;
    }

    .cta__HeroHome {
        width: 100%;
        height: auto;
        padding: 30px 30px;
    }

    .cta__HeroHome h2 {
        font-size: 31px;
    }

    .cta__HeroHome h4 {
        font-size: 18px;
    }

    .subheading__HeroHome {
        font-size: 16px;
    }

    .fieldLabel__Home {
        font-size: var(--ui);
        font-weight: 600;
    }

    .field__HeroHome {
        font-size: var(--ui);
        height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        border: transparent;
    }

    .cta-btn__HeroHome {
        font-size: 16px;
        margin-top: 10px;
        background-color: var(--text-clr);
        color: var(--text-light);
    }
}

@media screen and (max-width:360px) {
    .row__HeroHome {
        height: auto;
        padding: 20px 0;
    }

    .container__HeroHome {
        width: 100%;
        height: auto;
        padding: 10px 10px;
    }

    .cta__HeroHome {
        width: 100%;
        height: auto;
        padding: 30px 30px;
    }

    .cta__HeroHome h2 {
        font-size: 31px;
    }

    .cta__HeroHome h4 {
        font-size: 18px;
    }

    .subheading__HeroHome {
        font-size: 16px;
    }

    .fieldLabel__Home {
        font-size: var(--ui);
        font-weight: 600;
    }

    .field__HeroHome {
        font-size: var(--ui);
        height: 40px;
        padding: 0 10px;
        border-radius: 5px;
        border: transparent;
    }

    .cta-btn__HeroHome {
        font-size: 16px;
        margin-top: 10px;
        background-color: var(--text-clr);
        color: var(--text-light);
    }
}