/* 
-------------------------------------
           Page Not Found
-------------------------------------
*/

.container_notFoundPage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    height: 100vh;
    padding: 30px;
    background-color: antiquewhite;
}

.content_notFoundPage,
.image_notFoundPage {
    flex: 1;
}

.content_notFoundPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.image_notFoundPage img {
    width: 100%;
    height: auto;
}

.buttons_notFoundPage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}

.buttons_notFoundPage a {
    color: var(--text-light);
    background-color: var(--text-clr);
    border-radius: .4375rem;
    padding: 10px 35px;
    text-decoration: none;
}

.buttons_notFoundPage a:hover {
    background-color: var(--dark);
    color: var(--text-clr);
    font-weight: normal;
}



@media screen and (min-width:340px) and (max-width:768px) {
    .container_notFoundPage {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: .3125rem;
        padding: 30px;
        height: 100%;
    }

    .content_notFoundPage {
        margin-top: 5px;
    }

    .text_notFoundPage {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        line-height: 1.5;
    }

    .text_notFoundPage h1 {
        font-size: 36px;
        line-height: 1.2;
    }

    .text_notFoundPage h3 {
        font-size: 18px;
        line-height: 1.2;
    }

    .buttons_notFoundPage {
        flex-direction: column;
    }

    .buttons_notFoundPage a {
        width: 100%;
        text-align: center;
    }
}

@media screen and (min-width:541px) and (max-width:1200px) {
    .buttons_notFoundPage {
        display: flex;
        flex-direction: column;
    }

    .buttons_notFoundPage a {
        width: 100%;
        text-align: center;
    }

}