.button{
    padding: 15px 100px;
    border-radius: 10px;
    color: var(--text-light);
    font-size: var(--ui);
}

.button:hover {
    background-color: var(--accent);
}

.button,.container__Button a {
    text-decoration: none;
}

.primaryButton{
    background-color: var(--text-clr);
}

.secondaryButton{
    background-color: var(--text-light);
    color: var(--text-clr);
}

.OrderButton{
    border-radius: 10px;
    text-decoration: none;
    background-color: var(--accent);
    padding: 10px 30px;
    text-align: center;
}