.container__ServiceName {
    position: relative;
    height: 100vh;
    overflow: hidden;
    /* z-index: 1; */
}

.image__ServiceName {
    width: 100%;
    height: auto;
    opacity: 0.65;
}

.content__ServiceName {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    /* z-index: 2; */
}

.title__ServiceName,
.buttons__ServiceName {
    flex: 1;
}

.title__ServiceName {
    margin-top: 60px;
}

.heading__ServiceName {
    font-size: var(--h3);
    font-weight: 700;
}

.buttons__ServiceName {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 100px;
}

.description__ServiceName{
    display: flex;
    justify-content: center;
    gap: 60px;
    padding: 20px;
}

.description__ServiceName h3 {
    font-size: var(--h4);
    font-weight: 800;
}
.description__ServiceName p {
    font-size: var(--small-text);
    font-weight: 400;
}

.description__ServiceName div{
    background-color: var(--bg-clr);
    padding: 10px;
    border-radius: 10px;
}

.cta__wrapper {
    display: flex;
    justify-content: center;
    gap: 30px;
}


/* 
===========================================
Media Queries
===========================================
*/

@media screen and (max-width: 1366px) {
    .heading__ServiceName {
        font-size: var(--h4);
        font-weight: 800;
    }

    .subheading__ServiceName {
        font-weight: 400;
    }

    .button {
        padding: 10px 70px;
        border-radius: 10px;
    }
}

@media screen and (max-width:1024px) {}

@media screen and (max-width:768px) {}

@media screen and (max-width:540px) {}

@media screen and (max-width:450px) {}

@media screen and (min-width: 360px) and (max-width: 540px) {
    .container__ServiceName {
        height: auto;
    }

    .image__ServiceName {
        width: 100%;
        height: auto;
        opacity: 0.65;
    }

    .button {
        padding: 8px 40px;
        border-radius: 5px;
    }

    .cta__wrapper {
        display: flex;
        justify-content: center;
        gap: 8px;
    }
}

@media screen and (max-width:360px) {
    .container__ServiceName {
        height: auto;
    }

    .image__ServiceName {
        width: 100%;
        height: auto;
        opacity: 0.65;
    }

    .button {
        padding: 8px 40px;
        border-radius: 5px;
    }

    .cta__wrapper {
        display: flex;
        justify-content: center;
        gap: 8px;
    }
}