.page-container {
  position: relative;
}

/* 
=======================
Branding Page Styles
=======================
*/

.header_Branding{
  background-color: var(--bg-clr);
  color: var(--text-clr);
  font-family: var(--ff-body);
  font-size: var(--body-text);
  font-weight: 400;
  letter-spacing: var(--tracking);
  line-height: 1.4;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.header_Branding div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;
} 

.header_Branding img {
  width: 100%;
  height: auto;

}.header_Branding_text{
  padding: 0 60px;
line-height: 2;
}






/* 
=======================
Utilities
=======================
*/


.flow>*+* {
  margin-top: var(--flow-space, 1em)
}

.region {
  padding-bottom: var(--region-space, var(--space-l-2xl));
  padding-top: var(--region-space, var(--space-l-2xl))
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--wrapper-max-width, 85rem);
  padding-left: var(--gutter);
  padding-right: var(--gutter);
  position: relative
}


/*
=====================================
        Media Queries
=====================================
*/

/* Starts - Media Queries - Footer Component CSS  */

@media screen and (max-width: 1366px) {}

@media screen and (max-width:1024px) {}

@media screen and (max-width:768px) {}

@media screen and (max-width:540px) {}

@media screen and (max-width:450px) {}

@media screen and (max-width:376px) {}

@media screen and (max-width:360px) {}

/* Ends - Media Queries - Footer Component CSS  */