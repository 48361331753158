.container__Footer {
    background-color: var(--text-clr);
    color: var(--text-light);
    z-index: -1;
}

.row__Footer {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
}

.details--row__Footer {
    display: flex;
    justify-content: space-around;
    height: 100%;
    padding: 50px 0;
    gap: 0px;
}

.item--details {
    align-self: stretch;
    flex: 1;
    height: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: var(--space-m);
    padding: var(--space-m);
}

.details--row__Footer> :first-child {
    background-color: #0c2d47;
}

.details--row__Footer> :nth-child(2) {
    background-color: #0c2d47;
}

.details--row__Footer> :nth-child(3) {
    background-color: #1d384d;
}


.item--details i {
    font-size: var(--h4);
}

.item--details p {
    font-size: var(--ui);
}


.links--row__Footer {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}

.links--wrapper {
    flex: 2;
    display: flex;
    justify-content: space-evenly;
    gap: var(--space-m);
}

.subscribe-cta {
    flex: 1;
}


.links-section-list {
    list-style: none;
    padding: 0;
}

.links-section-list a {
    text-decoration: none;
}

.links-section-list li {
    margin-bottom: var(--space-xs);
}

.heading_links-section,
.subscribe-heading {
    font-size: var(--ui);
}

.links-item {
    font-size: var(--ui);
}

.cta-subheading {
    font-size: var(--small-text);
}

.form-input {
    padding: .625rem 15px;
    border: transparent solid .0625rem;
    font-family: var(--ff-ui);
    font-size: var(--ui);
}

.email-input {
    border-top-left-radius: .3125rem;
    border-bottom-left-radius: .3125rem;
}

.submit {
    background-color: var(--accent);
    border: var(--accent) solid .0625rem;
    border-top-right-radius: .3125rem;
    border-bottom-right-radius: .3125rem;
}

.subscribe-heading {
    margin-bottom: 20px;
}

.container_base--row__Footer {
    display: flex;
    justify-content: space-between;
    border-top: var(--text-light) solid 1px;
    padding-top: 20px;
}

.copyright-text a {
    text-decoration: none;
}

.copyright-details {
    font-size: var(--ui);
}

.icons--socials {
    display: flex;
    gap: 20px;
}

.socials i {
    font-size: 35px;
}



/*
=====================================
        Media Queries
=====================================
*/

/* Starts - Media Queries - Footer Component CSS  */

@media screen and (min-width: 360px) and (max-width: 1024px) {
    .row__Footer {
        width: 100%;
    }

    .details--row__Footer {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px 0;
        padding: 20px;
    }

    .links--row__Footer {
        display: flex;
        flex-direction: column;
        padding: 20px;
    }

    .links--wrapper {
        display: flex;
        flex-direction: column;
    }

    .heading_links-section,
    .subscribe-heading {
        font-size: 19px;
    }

    .container__Footer a,
    p {
        font-size: 16px;
        font-weight: 300;
    }

    .form-input {
        padding: 10px 5px;
        border: transparent solid .0625rem;
    }

    .email-input {
        border-top-left-radius: .3125rem;
        border-bottom-left-radius: .3125rem;
    }

    .submit {
        background-color: var(--accent);
        border: var(--accent) solid .0625rem;
        border-top-right-radius: .3125rem;
        border-bottom-right-radius: .3125rem;
    }

    .container_base--row__Footer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px 0;
        gap: 2px;
    }

    .container_base--row__Footer p {
        text-align: start;
        padding: 10px 20px;
    }

    .socials{
        padding: 20px;
    }
}

/* Ends - Media Queries - Footer Component CSS  */